<template>
  <subscription></subscription>
</template>

<script>
import Subscription from "/src/components/steps/Subscription.vue";
export default {
  name: "Mastery",
  components: {
    Subscription,
  },
};
</script>

<style></style>
